<template>
  <v-card>
    <v-card-title>
      {{$vuetify.lang.t('$vuetify.feeRule.label')}}
    </v-card-title>
    <v-card-text
      v-if="service.serviceIterm === 'HEALTH_REPORT'"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-list-item-group
            v-model="feeTypeSelected"
          >
            <v-list-item
              v-for="(sft, sfti) in serviceFrequencyTime"
              :key="`service-frequency-${ sfti }`"
              :value="sft.value"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ sft.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text
      v-else
    >
      <v-row>
        <v-col
        >
          <v-list-item-group
            v-model="feeTypeSelected"
          >
            <v-list-item
              v-for="(sft, sfti) in serviceFrequencyPackage"
              :key="`service-frequency-${ sfti }`"
              :value="sft.value"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ sft.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col
          cols="6"
        >
          <v-list-item-group
            v-model="frequencySelected"
          >
            <v-list-item
              v-for="(fr, fri) in feeRulesItems"
              :key="`fee-rule-${ fri }`"
              :value="fr.id"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ fr.ruleName }}( {{ fr.feeValue }}{{ $vuetify.lang.t('$vuetify.common.yuan') }} )</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
        small
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
        small
      >
         {{$vuetify.lang.t('$vuetify.common.ok')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>

  export default {
    props: {
      service: {
        type: Object,
        default: () => null
      },
      feeRules: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        frequencySelected: this.service.feeId !== null ? this.service.feeId : null,
        feeTypeSelected: this.service.serviceFrequency,
        serviceFrequencyTime: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.dayFrequency'),
            value: 'DAY_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.weekFrequency'),
            value: 'WEEK_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.monthFrequency'),
            value: 'MONTH_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.quarterFrequency'),
            value: 'QUARTER_FREQUENCY'
          }
        ],
        serviceFrequencyPackage: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.seriesFrequency'),
            value: 'SERIES_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.combinationPackage'),
            value: 'COMBINATION_PACKAGE'
          }
        ]
      }
    },

    computed: {
      feeRulesItems () {
        if ( this.feeRules  && this.feeTypeSelected) {
          return this.feeRules.filter( (item) => item.feeType === this.feeTypeSelected && item.ruleType === this.service.serviceIterm.replace('ECG_', '') )
        } else {
          return []
        }
      }
    },

    created () {
    },

    mounted () {
    },

    methods: {
      handleCancel () {
        this.$emit('cancel', true)
      },

      handleSubmit () {
        let data = {}
        if (this.feeTypeSelected) {
          data.serviceFrequency = this.feeTypeSelected
        }
        if (this.frequencySelected) {
          data.feeId = this.frequencySelected
        }
        this.$emit('submit', data)
      }

    }
  }
</script>
